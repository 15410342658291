<template>
	<div>
		<section id="job-profile" class="mt-2">
			<b-row class="match-height">
				<b-col xl="8" lg="8" md="12" sm="12" style="border-right: 1px solid #efefef;">
					<vue-perfect-scrollbar v-if="jobData" ref="refJobBasicPS" :settings="perfectScrollbarSettings"
						class="job-profile-scroller" style=" overflow-x:hidden;">
						<job-basic-profile :data="jobData"></job-basic-profile>
						<div v-if="jobData.Opportunities" class="text-justify px-2 mb-2">
							<h4 style="font-size: 20px; font-weight:600; margin-bottom: 1.3rem;">
								About Opportunity
							</h4>
							<span class="text-justify" style="font-size:16px">{{
									jobData.Opportunities
							}}</span>
						</div>
						<applied-candidate-list :job-id="jobId" :student-code="studentCode"></applied-candidate-list>
					</vue-perfect-scrollbar>
				</b-col>
				<b-col xl="4" lg="4" md="12" sm="12">
					<vue-perfect-scrollbar class="job-profile-scroller" ref="refJobDescPS"
						:settings="perfectScrollbarSettings" style=" overflow-x:hidden;">
						<job-roles-description :desc-data="jobData"></job-roles-description>
					</vue-perfect-scrollbar>
				</b-col>
			</b-row>
		</section>
	</div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import JobBasicProfile from "./components/JobBasicDetails.vue";
import JobRolesDescription from "./components/JobRolesDescription.vue";
import AppliedCandidateList from "./components/AppliedCandidatesList.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
	components: {
		BRow,
		BCol,
		JobBasicProfile,
		JobRolesDescription,
		AppliedCandidateList,
		VuePerfectScrollbar,
	},
	methods: {
		fetchJobData() {
			firebase
				.firestore()
				.collection("RecruiterJobs")
				.doc(this.jobId)
				.get()
				.then((doc) => {
					// console.log(doc.data());
					this.jobData = doc.data();
					this.jobData.id = doc.id
				});
		},
	},
	created() {
		this.jobId = this.$route.params.jobId;
		if (this.$store.state.mainUserCodes) {
			this.studentCode = this.$store.state.mainUserCodes.StudentCode;
			// console.log(this.studentCode)
		}
		// console.log(this.jobId)
		if (this.jobId) {
			this.fetchJobData();
		}
		// this.$store.watch(
		// 	(state) => {
		// 		return this.$store.state.mainUserCodes;
		// 	},
		// 	(newVal, oldVal) => {
		// 		this.studentCode = this.$store.state.mainUserCodes.StudentCode;
		// 	},
		// 	{ deep: true }
		// );
	},
	computed: {},
	data() {
		return {
			jobId: null,
			jobData: null,
			studentCode: null,
			perfectScrollbarSettings: {
				maxScrollbarLength: 150,
			}
		};
	},
};
</script>
<style>
.job-profile-scroller {
	height: calc(100vh - (3.35rem + 4.45rem + 4rem));
	
}
@media (max-width: 768px) {
	.job-profile-scroller {
		height: auto
	}
}
@media (max-width: 991px) {
	.job-profile-scroller {
		height: auto
	}
}
</style>
