<template>
	<div
		style="border-bottom: 1px solid #efefef;  margin-top: 0px !important;"
		class=" m-2 mx-0"
	>
		<b-row class="match-height">
			<b-col xl="10" md="10" class="align-items-start">
				<b-media no-body class="w-100">
					<b-media-aside class="mr-1">
						<b-avatar
							class=" badge-minimal avatar-border-2 text-primary"
							size="120"
							:src="data.logo"
							:text="avatarText(data.CompanyName)"
							variant="transparent"
							style="border-radius: 52px !important;"
						/>
					</b-media-aside>
					<b-media-body>
						<h2 class=" font-weight-bold">
							{{ data.JobTitle }}
						</h2>
						<h4
							class="mt-50"
							style="font-size: 20px; font-weight:600"
						>
							{{ data.CompanyName }}
						</h4>
						<span
							class="mt-25 "
							style="font-size: 16px;font-weight:600"
							>₹{{ data.ctc_lower }} -
							{{ data.ctc_upper }} LPA</span
						>
						<p
							class="mt-25 "
							style="font-size: 16px;font-weight:600"
						>
							{{ methodGenerateLocation(data.location) }}
						</p>
						<div v-if="data" style=" margin-top:32px" class="">
							<div
								class="d-flex  mb-3 justify-content-between mr-2 w-100"
							>
								<div
									class="d-flex flex-column justify-content-center"
								>
									<span
										class="mb-50 align-self-center"
										style="font-size: 16px;font-weight:500"
										>Total Applied</span
									>
									<h4
										class=" font-weight-bold align-self-center"
										style="font-size: 20px; "
									>
										{{ applied || 0 }}
									</h4>
								</div>
								<div
									style="width:1px; height:56px; border:1px solid #CED7DD"
								></div>
								<div
									class="d-flex flex-column justify-content-center"
								>
									<span
										class="mb-50 align-self-center"
										style="font-size: 16px;font-weight:500"
										>Total Interviewed</span
									>
									<h4
										class=" font-weight-bold align-self-center"
										style="font-size: 20px; "
									>
										{{ interviewed || 0 }}
									</h4>
								</div>
							</div>
						</div>
					</b-media-body>
				</b-media>
			</b-col>
			<b-col xl="2" md="2" class="align-items-end">
				<small class="text-nowrap mt-50">
					Posted on
					{{
						new Intl.DateTimeFormat("en-US", {
							month: "short",
							day: "numeric",
						}).format(new Date(data.timestamp.toDate()))
					}}</small
				>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import {
	BRow,
	BCol,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BMedia,
	BButton,
	BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import "firebase/firestore";
import firebase from "firebase/app";

export default {
	components: {
		BRow,
		BCol,
		BMediaBody,
		BMedia,
		BMediaAside,
		BAvatar,
		BButton,
		BSpinner,
	},
	props: {
		data: {
			type: Object,
		},
	},
	data(){
		return {
			applied: 0,
			interviewed: 0
		}
	},
	watch:{
		data(){
			if(this.data){
				this.getTotalAppliedforCollege()
			}
		}
	},
	methods: {
		avatarText,
		getTotalAppliedforCollege(){
			if(this.data.category == "On-Campus"){
			var ref = firebase.firestore().collection("RecruiterJobs").doc(data.id).collection("AppliedCandidates")
			var studentCode = this.$store.state.mainUserCodes.StudentCode
			ref.where("StudentCode","==", studentCode).get().then((students) => {
				this.applied = students.size
				students.forEach((stud) => {
					if(stud.data().isInterviewDone){
						this.interviewed = this.interviewed + 1
					}
				})
			})
			}else{
				this.applied = this.data.TotalApplied
				this.interviewed = this.data.TotalInterviewed
			}

		},
		methodGenerateLocation(location) {
			if(location){
			var locationStr = "";
			location.forEach((val) => {
				var valueList = val.split(",");
				if (locationStr.length > 0) {
					locationStr = locationStr + "," + " " + valueList[0];
				} else {
					locationStr = valueList[0];
				}
			});
			if (locationStr.length > 25) {
				return locationStr.substring(0, 25) + "...more";
			} else {
				return locationStr;
			}
		}else return ''
		},
	},
};
</script>
