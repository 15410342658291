<template>
	<div style="padding-left: 32px;padding-right: 32px;" v-if="descData">
		<h4  style="font-size: 20px; font-weight:600;margin-bottom: 1.3rem;">
			Roles & Responsibilties
		</h4>
		<!-- <ul class="m-0" style="padding-inline-start: 20px;">
			<li
				v-for="(item, index) in descData.Roles"
				:key="index"
				class=" mb-1"
			>
				<span class="text-justify" style="font-size:16px">{{
					item
				}}</span>
			</li>
		</ul> -->
		<b-list-group >
			<b-list-group-item class="d-flex mb-50" v-for="(item,index) in descData.Roles" :key="index" style="border:none;padding:0;background-color: transparent;">
						<u-icon icon="fi-sr-rec" v-if="item.length > 1" class="mr-1 mt-50" size="8" />
				<span class="text-justify" v-if="item.length > 1" style="font-size:16px">{{ item }}</span>
			</b-list-group-item>
		</b-list-group>
		<h4 class=" mt-3" style="font-size: 20px; font-weight:600;margin-bottom: 1.3rem;">
			Required Skills
		</h4>
		<!-- <ul class="m-0" style="padding-inline-start: 20px;">
			<li
				v-for="(item, index) in descData.SkillsList"
				:key="index"
				class=" mb-1"
			>
				<span class="text-justify" style="font-size:16px">{{
					item
				}}</span>
			</li>
		</ul> -->
		<b-list-group>
			<b-list-group-item class="d-flex mb-1" v-for="(item,index) in descData.SkillsList" :key="index" style="border:none;padding:0;background-color: transparent;">
				
						<u-icon icon="fi-sr-rec" class="mr-1 mt-50" size="8" />
				<span class="text-justify" style="font-size:16px">{{ item }}</span>
			</b-list-group-item>
		</b-list-group>
	</div>
</template>
<script>
import {
	BCard,
	BRow,
	BCol,
	BButton,
	BAvatar,
	BLink,
	BListGroup,
	BListGroupItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
	components: {
		BCard,
		BAvatar,
		BListGroup,
		BListGroupItem,
	},
	props: {
		descData: {
			type: Object,
		},
	},
	methods: {
		avatarText,
	},
};
</script>
