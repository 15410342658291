var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-2  pt-2",staticStyle:{"border-top":"1px solid #efefef"}},[_c('h4',{staticStyle:{"font-size":"20px","font-weight":"600","margin-bottom":"1.3rem"}},[_vm._v(" Applied Candidates ")]),(_vm.tableData)?_c('b-card',{staticClass:"card-company-table",attrs:{"no-body":""}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.tableData,"responsive":"","fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('h6',{staticClass:"text-muted align-self-center"},[_vm._v(" No Applications ")])]}},{key:"cell(name)",fn:function(data){return [_c('b-media',{staticStyle:{"align-items":"center"},attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticClass:"mr-1 text-primary",staticStyle:{"border-radius":"16px !important"},attrs:{"square":"","size":"42","src":data.item.profile,"text":_vm.avatarText(data.item.name),"variant":"transparent","to":{
								name: 'student-profile',
								params: { studentId: data.item.id },
							}}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"text-nowrap  text-capitalize text-default",staticStyle:{"color":"#043c57"},attrs:{"to":{
							name: 'student-profile',
							params: { studentId: data.item.id },
						}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}},{key:"cell(course)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-normal mb-25"},[_vm._v(_vm._s(data.item.course))])])]}},{key:"cell(batch)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-normal mb-25"},[_vm._v(_vm._s(data.item.batch))])])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize font-weight-bold",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(
							data.item.status
						)))}},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}},{key:"cell(location)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"font-weight-normal mr-1"},[_vm._v(_vm._s(_vm.methodGenerateLocation(data.item.location)))])])]}}],null,false,1785665234)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }