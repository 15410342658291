<template>
	<div class="m-2  pt-2" style="border-top: 1px solid #efefef;">
		<h4  style="font-size: 20px; font-weight:600;margin-bottom: 1.3rem;">
			Applied Candidates
		</h4>
		<b-card v-if="tableData" no-body class="card-company-table">
			<b-table
				:items="tableData"
				responsive
				:fields="fields"
				class="mb-0"
				show-empty
			>
				<template #empty="scope" class="justify-content-center">
					<h6 class="text-muted align-self-center">
						No Applications
					</h6>
				</template>

				<!-- company -->
				<template #cell(name)="data">
					<b-media vertical-align="center" style="align-items: center;">
						<template #aside>
							<b-avatar
								class="mr-1 text-primary"
								square
								size="42"
								:src="data.item.profile"
								:text="avatarText(data.item.name)"
								variant="transparent"
								style="border-radius: 16px !important;"
								:to="{
									name: 'student-profile',
									params: { studentId: data.item.id },
								}"
							>
							</b-avatar>
						</template>
						<b-link
							:to="{
								name: 'student-profile',
								params: { studentId: data.item.id },
							}"
							class="text-nowrap  text-capitalize text-default" style="color:#043c57"
						>
							{{ data.item.name }}
						</b-link>
					</b-media>
				</template>
				<template #cell(course)="data">
					<div class="d-flex flex-column">
						<span class="font-weight-normal mb-25">{{
							data.item.course
						}}</span>
						<!-- <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span> -->
					</div>
				</template>
				<template #cell(batch)="data">
					<div class="d-flex flex-column">
						<span class="font-weight-normal mb-25">{{
							data.item.batch
						}}</span>
						<!-- <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span> -->
					</div>
				</template>

				<!-- revenue -->
				<template #cell(status)="data">
					<b-badge
						pill
						:variant="
							`light-${resolveUserStatusVariant(
								data.item.status
							)}`
						"
						class="text-capitalize font-weight-bold"
					>
						{{ data.item.status }}
					</b-badge>
				</template>

				<!-- sales -->
				<template #cell(location)="data">
					<div class="d-flex align-items-center">
						<span class="font-weight-normal mr-1">{{
							methodGenerateLocation(data.item.location)
						}}</span>
					</div>
				</template>
			</b-table>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BTable,
	BAvatar,
	BImg,
	BBadge,
	BMedia,
	BLink,
} from "bootstrap-vue";
import firebase from "firebase/app";
import "firebase/firestore";
import { avatarText } from "@core/utils/filter";

export default {
	components: {
		BCard,
		BTable,
		BAvatar,
		BImg,
		BBadge,
		BMedia,
		BLink,
	},
	props: {
		jobId: {
			type: String,
		},
		studentCode: {
			type: String,
		},
	},
	watch: {
		studentCode() {
			if (this.studentCode) {
				this.getStudentsData();
			}
		},
	},
	methods: {
		getStudentsData() {
			const thisIns = this;
			// console.log(thisIns.studentCode);
			const studentRef = firebase.firestore().collection("StudentID");
			let jobRef = firebase
				.firestore()
				.collection("RecruiterJobs")
				.doc(thisIns.jobId)
				.collection("AppliedCandidates")
				.where("StudentCode", "==", thisIns.studentCode);

			jobRef.get().then((docs) => {
				docs.forEach((document) => {
					var current = document.data().currentRound || null;
					studentRef
						.doc(document.id)
						.get()
						.then((doc) => {
							var obj = {};

							obj.id = doc.id;
							obj.name = doc.data().FullName;
							obj.course = doc.data().courseName;
							obj.batch = doc.data().batch;
							obj.profile = doc.data().profilephoto;
							obj.jobid = thisIns.jobId;
							obj.currentRound = document.data().currentRound;
							obj.isHired = document.data().isHired;
							obj.status =
								document.data()[
									`Round${obj.currentRound}_status`
								] || "Applied";
							if (obj.status == "Hired") {
								if (
									typeof document.data().joiningStatus !=
									"undefined"
								) {
									obj.status = document.data().joiningStatus;
								}
							}
							thisIns.tableData.push(obj);
						});
				});
			});
		},
	},
	created() {
		if (this.studentCode) {
			this.getStudentsData();
		}
	},
	data() {
		return {
			tableData: [],
			fields: [
				{ key: "name", label: "STUDENT NAME" },
				{ key: "course", label: "COURSE" },
				{ key: "batch", label: "BATCH" },
				{ key: "status", label: "STATUS" },
				// { key: "location", label: "JOB LOCATION" },
			],
		};
	},
	setup() {
		const resolveUserStatusVariant = (status) => {
			if (status === "Screening") return "warning";
			if (status === "Active") return "success";
			if (status === "Closed") return "danger";
			return "primary";
		};

		const methodGenerateLocation = (location) => {
			if (location) {
				var locationStr = "";
				location.forEach((val) => {
					var valueList = val.split(",");
					if (locationStr.length > 0) {
						locationStr = locationStr + "," + " " + valueList[0];
					} else {
						locationStr = valueList[0];
					}
				});
				if (locationStr.length > 25) {
					return locationStr.substring(0, 25) + "...more";
				} else {
					return locationStr;
				}
			} else return "-";
		};
		return {
			resolveUserStatusVariant,
			methodGenerateLocation,
			avatarText,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
	.dark-layout & {
		background: $theme-dark-body-bg !important;
	}
}
</style>
